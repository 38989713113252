export class Tabs {
    private container: HTMLElement;
    private openingHourButtons: NodeListOf<HTMLButtonElement>;
    private appointmentButtons: NodeListOf<HTMLButtonElement>;
    private addressButtons: NodeListOf<HTMLButtonElement>;

    private openingHourTab: HTMLElement;
    private appointmentTab: HTMLElement;
    private addressTab: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;

        this.openingHourButtons = this.container.querySelectorAll(".category-button.opening-hours");
        this.appointmentButtons = this.container.querySelectorAll(".category-button.appointment");
        this.addressButtons = this.container.querySelectorAll(".category-button.address");

        this.openingHourTab = this.container.querySelector("#OpeningHoursContent");
        this.appointmentTab = this.container.querySelector("#AppointmentContent");
        this.addressTab = this.container.querySelector("#AddressContent");

        this.openingHourButtons.forEach((button) => {
            button.addEventListener("click", () => {
                if (!button.classList.contains("active")) {
                    this.clearActive();
                    this.openingHourButtons.forEach((b) => {
                        b.classList.add("active");
                    });
                    this.openingHourTab.classList.add("active");
                }
            });
        });

        this.appointmentButtons.forEach((button) => {
            button.addEventListener("click", () => {
                if (!button.classList.contains("active")) {
                    this.clearActive();
                    this.appointmentButtons.forEach((b) => {
                        b.classList.add("active");
                    });
                    this.appointmentTab.classList.add("active");
                }
            });
        });

        this.addressButtons.forEach((button) => {
            button.addEventListener("click", () => {
                if (!button.classList.contains("active")) {
                    this.clearActive();
                    this.addressButtons.forEach((b) => {
                        b.classList.add("active");
                    });
                    this.addressTab.classList.add("active");
                }
            });
        });
    }

    private clearActive() {
        this.openingHourButtons.forEach((button) => {
            button.classList.remove("active");
        });

        this.appointmentButtons.forEach((button) => {
            button.classList.remove("active");
        });

        this.addressButtons.forEach((button) => {
            button.classList.remove("active");
        });

        this.openingHourTab.classList.remove("active");
        this.appointmentTab.classList.remove("active");
        this.addressTab.classList.remove("active");
    }
}
