import Swiper from "swiper";
import { Autoplay, EffectFade, FreeMode } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";

document.querySelectorAll<HTMLElement>(".hero-swiper").forEach((container) => {
    new Swiper(container, {
        modules: [Autoplay, EffectFade],
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            waitForTransition: false,
        },
        effect: "fade",
    });
});

document.querySelectorAll<HTMLElement>(".categories-swiper").forEach((container) => {
    new Swiper(container, {
        modules: [FreeMode],
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 30,
        freeMode: true,
    });
});
