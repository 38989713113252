import "./Navigation";
import "./SwiperGallerys";
import "./ScrollToTop";
import ScrollToTop from "./ScrollToTop";
import { Tabs } from "./Tabs";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const backToTopButton = document.querySelector<HTMLElement>("#BackToTop");
if (backToTopButton) {
    new ScrollToTop(backToTopButton);
}

const infoSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".information-section");
infoSections.forEach((section) => {
    new Tabs(section);
});
